<template>
  <div class="red-background wrapper">
    <div class="heading">{{ $t('statistics') }}</div>
    <div class="content">
      <div class="content-row" v-on-clickaway="close">
        {{$t('gamesScored')}}: {{ allGames.length }}
      </div>
      <div class="content-row" v-on-clickaway="close">
        <table>
          <tr>
            <th>{{$t('rank')}}</th>
            <th>{{$t('player')}}</th>
            <th>{{$t('wins')}}</th>
          </tr>
          <tr v-for="(player, idx) in ranking"
              :key="player.name">
            <td>{{ idx + 1 }}.</td>
            <td>{{ player.name }}</td>
            <td>{{ player.won }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="button-panel">
      <div class="button"
           @click="resetStatistics">
        {{$t('resetStats')}}
      </div>
    </div>
    <floating-close-button @close="$router.go(-1)"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FloatingCloseButton from "@/components/FloatingCloseButton";
import {mixin as clickAway} from 'vue-clickaway'

export default {
  components: {FloatingCloseButton},
  mixins: [clickAway],
  computed: {
    ...mapGetters(['allGames']),
    ranking() {
      const ranking = []
      this.allGames.map(g => g.players).forEach(playerArr => {
        playerArr.forEach(p => {
          let rank = ranking.find(r => r.name === p.name)
          if (!rank) {
            rank = {
              name: p.name,
              won: 0
            }
            ranking.push(rank)
          }
          if(p.winner)
            rank.won += 1
        })
      })
      return ranking.sort((r1, r2) => r2.won - r1.won)
    }
  },
  methods: {
    ...mapActions(['resetStatistics']),
    close(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  justify-content: center;
  position: absolute;
}

.heading {
  display: flex;
  font-size: x-large;
  margin-bottom: 0.6rem;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.content-row {
  display: flex;
  justify-content: center;
}

table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

th {
  padding-left: 1rem;
  padding-right: 1rem;
}

td {
  text-align: center;
}

.button-panel {
  display: flex;
  justify-content: center;
}
</style>

